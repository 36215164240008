
import { sendContact } from '@/api/contact.service.js'
export default {
  name: 'ContactApi',
  data: () => ({
    request: null,
  }),
  computed: {
    state() {
      return this.request?.state
    },
  },
  methods: {
    contact(email, body, type) {
      if (!body) return
      if (!email && this.$auth.loggedIn) {
        email = this.$auth.user.email
      }
      if (typeof body !== 'string') {
        body = JSON.stringify(body, null, 2)
      }
      this.request = this.$api.run(sendContact, { type, email, body })
      this.request.then(() => this.$emit('success'))
    },
  },
}
