
import {
  mdiThumbDownOutline,
  mdiThumbUpOutline,
  mdiThumbDown,
  mdiThumbUp,
} from '@mdi/js'
import ContactApi from "@/components/api/ContactApi.vue";
import {setLiked} from '@/api/feedback.service.js'

export default {
  name: 'ResultCard',
  components: {
    ContactApi,
    ResultFeedbackModal: () => import('@/components/feature/ResultFeedbackModal'),
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiThumbDownOutline,
      mdiThumbUpOutline,
      mdiThumbDown,
      mdiThumbUp,
      showFeedbackModal: false,
      isLoading: false,
      rating: this.result.rating,
    }
  },
  methods: {
    async setRating(rating) {
      if (this.isLoading || this.rating) return
      this.isLoading = true
      try {
        await this.$api.run(setLiked, this.result.id, rating)
        this.rating = rating
        if (rating < 1) {
          this.showFeedbackModal = 1
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
